<template>
  <v-container>
    <div>
      <div class="d-flex justify-center">
        <v-checkbox v-model="changeAvatar"></v-checkbox>
        <p class="text-center font-weight-medium mt-5">Choose your avatar:</p>
      </div>
      <div class="d-flex justify-center mb-11">
        <v-file-input
          label="File input"
          filled
          prepend-icon="mdi-camera"
          accept="image/png, image/jpeg, image/bmp"
          v-model="files"
          :disabled="!changeAvatar || files == undefined"
          :clearable="false"
        ></v-file-input>
      </div>
      <div class="d-flex justify-center">
        <v-checkbox v-model="changeColor"></v-checkbox>
        <p class="text-center font-weight-medium mt-5">Choose your color:</p>
      </div>
      <div class="d-flex justify-center mb-16">
        <v-color-picker
          dot-size="25"
          swatches-max-height="200"
          v-model="color"
          :disabled="!changeColor"
        ></v-color-picker>
      </div>
      <div class="d-flex justify-center">
        <v-btn
          fab
          @click="saveImage()"
          color="primary"
          class="ma-3"
          :disabled="!changeColor && (files.name == undefined || !changeAvatar)"
        >
          <v-icon dark>mdi-content-save-all</v-icon>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";
// import moment from "moment";

export default {
  data: () => ({
    files: [],
    imageName: "",
    imageUrl: "",
    imageFile: "",
    color: "#ffffff",
    changeAvatar: false,
    changeColor: false,
  }),

  methods: {
    saveImage() {
      var _self = this;
      let UserID = firebase.auth().currentUser.uid;

      if (_self.changeAvatar) {
        var storageRef = firebase.storage().ref().child(this.files.name);
        var reader = new FileReader();
        reader.readAsDataURL(this.files);
        reader.onload = function () {
          storageRef.putString(reader.result, "data_url").then((snapshot) => {
            firebase
              .firestore()
              .collection("users")
              .doc(UserID + "/profile/preferences")
              .set({ imageUrl: snapshot.ref.fullPath }, { merge: true });
          });
        };
      }
      if (_self.changeColor) {
        firebase
          .firestore()
          .collection("users")
          .doc(UserID + "/profile/preferences")
          .set({ color: _self.color }, { merge: true });
      }

      this.$router.push("/home");
    },
  },
};
</script>
